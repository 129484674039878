export default class CircleProgress {
	constructor(circle, percent) {
		this.circle = document.querySelectorAll(circle);
		this.percent = percent;
	}

	progressChart() {
		this.circle.forEach((i) => {
			let radius = i.r.baseVal.value;
			let circumference = radius * 2 * Math.PI;
			i.style.strokeDasharray = circumference;

			let porcentagem = Number(i.parentNode.querySelector(this.percent).innerHTML);
			setProgress(porcentagem);

			function setProgress(percent) {
				i.style.strokeDashoffset = circumference - (percent / 100) * circumference;
			}
		});
	}

	init() {
		this.progressChart();
		return this;
	}
}
